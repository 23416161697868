import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { Container } from "../../components/container"
import SEO from "../../components/seo"
import styles from "./cookies.module.css"

const CookiesPageDe = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title="Cookies"
      />
      <Container className={styles.cookies}>
        <h1 className="text-center">
          Cookies-Dateien
        </h1>

        <section>
            <p>
              In diesem Dokument informieren wir Sie in Übereinstimmung mit der Verordnung des Europäischen Parlaments und des Rats (EU) 2016/679 vom 27. April 2016 über den Schutz natürlicher Personen in Zusammenhang mit der Verarbeitung personenbezogener Daten und über den freien Verkehr dieser Daten und über die Aufhebung der Richtlinie 95/46/EG über die Verarbeitung personenbezogener Daten, die beim Besuch der Website unserer Gesellschaft Nutritionpro GmbH, Dingolfinger Straße 15, 81673 München („Verwalter“) mittels Cookies-Dateien stattfindet.
            </p>
            <p>
              Auf den Seiten driverama.de verwenden wir Cookies, um Ihnen bestmögliche Arbeit auf unserer Webseite zu bieten. Diese Dateien können Informationen über Ihr Verhalten auf unserer Website, Ihr Endgerät und Ihre Präferenzen sammeln. Die von uns verarbeiteten Informationen dienen nicht dazu, Sie persönlich zu identifizieren, sondern dazu, Ihnen die bestmögliche Benutzerumgebung und gezieltere Werbebotschaften zu bieten. Das Sperren bestimmter Arten von Cookies kann die Webseiten und Dienste, die wir Ihnen anbieten, einschränken. Wenn Sie alle Cookies sperren, müssen Sie Ihre Voreinstellungen für unsere Website bei Ihrem nächsten Besuch immer wieder neu vornehmen. Sie müssen Ihre Einstellungen auch dann erneut festlegen, wenn Sie zu einem anderen Gerät oder Browser wechseln.
            </p>
            <details>
              <summary>
                <h3>WIR VERARBEITEN FOLGENDE ARTEN VON COOKIES:</h3> 
                <svg className={styles.summaryIcon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13L5 5M5 13l8-8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </summary>
              <p>
                Essential Cookies:<br/>
                Dies sind Cookies, die für das Funktionieren unserer Website unerlässlich sind, immer verwendet werden und nicht deaktiviert werden können. Dabei speichern diese Cookies zugleich keine persönlichen Informationen, die uns helfen würden, Sie zu identifizieren. Sie funktionieren als Reaktion auf Aktionen, die Sie auf unserer Website durchführen, wie z. B. das Einloggen im Rahmen Ihres Profils, das Ausfüllen eines Fragebogens usw.
                Die Rechtsgrundlage für die Verarbeitung dieser Cookies sind unsere berechtigten Interessen.
              </p>
              <table>
                <thead>
                  <tr><th>COOKIE-BEZEICHNUNG</th><th>BESCHREIBUNG</th><th>DAUER DER SPEICHERUNG</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>auth.csrf</td>
                    <td>CSRF&nbsp;wird auch als Abkürzung bei Verteidigungsmaßnahmen gegen CSRF-Angriffe, wie z. B. Techniken, die Überschriftsdaten, Formulardaten oder Cookies nutzen, um diese Angriffe zu erproben und zu verhindern, verwendet.</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>auth.session</td>
                    <td>Ein Session-Cookie (auch als In-Memory-Cookie, transientes oder nicht-persistentes Cookie bekannt) verbleibt nur solange im temporären Speicher, wie sich der Benutzer auf einer Website befindet.&nbsp;Session-Cookies&nbsp;laufen ab oder werden gelöscht, sobald der Benutzer den Internet-Browser schließt.</td>
                    <td>30 Tage</td>
                  </tr>
                  <tr>
                    <td>auth.expiry</td>
                    <td>Cookies dienen der Authentifizierung sowie der Aufhebung der Authentifizierung.</td>
                    <td>30 Tage</td>
                  </tr>
                  <tr>
                    <td>__next_preview_data</td>
                    <td>Dieses Cookie ermöglicht es dem Benutzer, Entwurfsinhalte sofort auf derselben URL wie die statistisch generierte Routen anzuzeigen.</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>NEXT_LOCALE</td>
                    <td>Next.js unterstützt die Aufhebung des Accept-Language-Headers durch ein NEXT_LOCALE=the-locale-Cookie. Dieses Cookie kann mithilfe einer Sprachumschaltung eingesetzt werden und verwendet die in dem Cookie aufgezeichnete Locale, wenn der Benutzer diese Website erneut besucht.</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>Facebook</td>
                    <td>Cookies, die von Dritten verwendet werden, um sich in das Konto des Nutzers einzuloggen, im Falle, dass der Benutzer sein Facebook.com-Profil zum Einloggen verwendet. <strong>Es handelt sich um Cookies eines Dritten.</strong> Informationen zu den verwendeten Cookies finden Sie hier: <a href="https://www.facebook.com/policies/cookies">https://www.facebook.com/policies/cookies</a></td>
                    <td>Gemäß Einstellung der 3. Partei</td>
                  </tr>
                  <tr>
                    <td>Google</td>
                    <td>Cookies, die von Dritten verwendet werden, um sich in das Konto des Nutzers einzuloggen, im Falle, dass der Benutzer sein Google.com-Profil zum Einloggen verwendet. <strong>Es handelt sich um Cookies eines Dritten.</strong> Informationen zu den verwendeten Cookies finden Sie hier: <a href="https://policies.google.com/technologies/cookies#types-of-cookies">https://policies.google.com/technologies/cookies#types-of-cookies</a></td>
                    <td>Gemäß Einstellung der 3. Partei</td>
                  </tr>
                </tbody>
              </table>
              <p>Leistungscookies: <br/>
                Diese Art von Cookie hilft uns, die Leistung unserer Website zu verbessern, indem Besuche und Betriebsquellen gezählt werden können. Sie helfen, den Besucherverkehr und damit die Popularität einzelner Teile der Website zu ermitteln. Alle von diesen Cookies erhobenen Daten sind vollständig anonym. Es ist möglich, diese Art von Cookie auf unserer Website zu sperren, in diesem Fall können wir nicht nachvollziehen, wann Sie die Webseite besucht haben oder welche konkreten Teile unserer Webseite Sie besucht haben.
                Die Rechtsgrundlage für die Verarbeitung dieser Cookies ist Ihre freiwillig erteilte Einwilligung, die Sie jederzeit widerrufen können.
              </p>

              <table>
                <thead>
                  <tr><th>COOKIE-BEZEICHNUNG</th><th>BESCHREIBUNG</th><th>DAUER DER SPEICHERUNG</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Hotjar Tracking</td>
                    <td><strong>Es handelt sich um Cookies eines Dritten.</strong> Hotjar-Cookies dienen der Anzeige des korrekten Inhalts für den Benutzer, ohne eine Person selbst zu identifizieren.<a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information#cookies-set-by-the-hotjar-script">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information#cookies-set-by-the-hotjar-script</a></td>
                    <td>Bis zu 1 Jahr</td>
                  </tr>
                  <tr>
                    <td>Google Analytics 4 _ga</td>
                    <td>Zur Nachverfolgung der Benutzer.</td>
                    <td>2 Tage</td>
                  </tr>
                  <tr>
                    <td>Google Analytics 4 _gid</td>
                    <td>Dieses <strong>Cookie</strong>&nbsp;wird durch Google Analytics installiert. Das <strong>Cookie</strong>&nbsp;dient der Speicherung von Informationen über die Art und Weise, wie Besucher eine Website nutzen und unterstützt die Erstellung eines Analyseberichts über den Erfolg der Website.</td>
                    <td>24 Stunden</td>
                  </tr>
                  <tr>
                    <td>Google Analytics 4 _ga_&lt;\container-id&gt;</td>
                    <td>_ga cookie dient der Identifizierung einmaliger Benutzer.</td>
                    <td>2 Jahre</td>
                  </tr>
                </tbody>
              </table>

              <p>
                Marketing-Cookies: <br/>
                Diese Cookies werden verwendet, um ein bestmögliches Profil Ihrer Interessen zu erstellen und zur nachfolgenden Anzeige der Werbemitteilungen anhand Ihrer Präferenzen. Diese Cookies können mit den Empfängern der personenbezogenen Daten geteilt werden, um ein Profil Ihrer Interessen zu erstellen, damit relevante Werbung auf anderen Webseiten angezeigt werden kann. Sie arbeiten auf Grundlage der eindeutigen Identifizierung Ihres Geräts und des Internetbrowsers. Wenn Sie diese Art von Cookie nicht zulassen, sind die Werbebotschaften weniger gezielt und möglicherweise nicht für Ihre Interessen relevant.
                Die Rechtsgrundlage für die Verarbeitung dieser Cookies ist Ihre freiwillig erteilte Einwilligung, die Sie jederzeit widerrufen können.
              </p>

              <table>
                <thead>
                  <tr><th>COOKIE-BEZEICHNUNG</th><th>BESCHREIBUNG</th><th>DAUER DER SPEICHERUNG</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>_gads</td>
                    <td>Mit diesem Cookie können Websites Google Ads einschließlich personalisierter Werbung anzeigen. GOOGLE – DRITTANBIETER-COOKIE</td>
                    <td>13 Monate</td>
                  </tr>
                  <tr>
                    <td><em>gac</em></td>
                    <td>Cookies, die mit ‘<em>gac</em>’ beginnen, stammen von Google Analytics und werden von Inserenten  genutzt, um die Benutzeraktivität sowie den Erfolg ihrer Werbekampagnen zu messen. GOOGLE – DRITTANBIETER-COOKIE</td>
                    <td>90 Tage</td>
                  </tr>
                  <tr>
                    <td><em>glc</em></td>
                    <td>Cookies, die ‘<em>gcl</em>’ beginnen, stammen von Google Analytics und werden vorrangig verwendet, um Inserenten helfen, festzustellen, wie oft Benutzer, die auf ihre Werbung klicken, letztendlich auf der jeweiligen Website tätig werden z. B. indem sie kaufen. GOOGLE – DRITTANBIETER-COOKIE</td>
                    <td>90 Tage</td>
                  </tr>
                  <tr>
                    <td>ANID</td>
                    <td>Sofern Sie personalisierte Werbung aktiviert haben, wird ‘ANID’ genutzt, um diese Einstellung zu speichern. Dies gilt im Europäischen Wirtschaftsraum (EWR), der Schweiz und dem Vereinigten Königreich (GB) für 13 Monate, in allen anderen Ländern für 24 Monate. Wenn die personalisierte Werbung deaktiviert ist, wird „ANID“ genutzt, um diese Einstellung bis 2030 zu speichern. „NID“ läuft 6 Monate nach der letzten Nutzung durch den Benutzer ab. „IDE“ bleibt im EWR, der Schweiz und GB 13 Monate aktiviert, im Rest der Welt 24 Monate.</td>
                    <td>13 Monate</td>
                  </tr>
                  <tr>
                    <td>NID</td>
                    <td>Sofern Sie personalisierte Werbung aktiviert haben, wird „ANID“ dazu genutzt, diese Einstellung zu speichern, die dann im Europäischen Wirtschaftsraum (EWR), der Schweiz und dem Vereinigten Königreich (GB) 13 Monate aktiviert ist, in allen anderen Ländern 24 Monate. Wenn die personalisierte Werbung deaktiviert ist, wird „ANID“ genutzt, um diese Einstellung bis 2030 zu speichern. „NID“ läuft 6 Monate nach der letzten Nutzung durch den Benutzer ab. „IDE“ bleibt im EWR, der Schweiz und GB 13 Monate aktiviert, im Rest der Welt 24 Monate.</td>
                    <td>6 Monate</td>
                  </tr>
                  <tr>
                    <td>IDE</td>
                    <td>Sofern Sie personalisierte Werbung aktiviert haben,  wird „ANID“ dazu genutzt, diese Einstellung zu speichern, die dann im Europäischen Wirtschaftsraum (EWR), der Schweiz und dem Vereinigten Königreich (GB) 13 Monate aktiviert ist, in allen anderen Ländern 24 Monate. Wenn die personalisierte Werbung deaktiviert ist, wird „ANID“ genutzt, um diese Einstellung bis 2030 zu speichern. „NID“ läuft 6 Monate nach der letzten Nutzung durch den Benutzer ab. „IDE“ bleibt im EWR, der Schweiz und GB 13 Monate aktiviert, im Rest der Welt 24 Monate.</td>
                    <td>13 Monate</td>
                  </tr>
                  <tr>
                    <td>DSID</td>
                    <td>‘DSID’ dient der Identifizierung eines angemeldeten Benutzers auf nicht von Google stammenden Websites und speichert, ob der Benutzer der Personalisierung von Werbung zugestimmt hat  GOOGLE – DRITTANBIETER-COOKIE</td>
                    <td>2 Wochen</td>
                  </tr>
                </tbody>
              </table>
            </details>

            <details>
              <summary>
                <h3>Rechte der Subjekte der personenbezogenen Daten</h3> 
                <svg className={styles.summaryIcon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13L5 5M5 13l8-8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </summary>
              <p>
                Sämtliche Informationen über Ihre Rechte in Bezug auf die Verarbeitung Ihrer personenbezogenen Daten, einschließlich detaillierter Informationen über den Verwalter und den Datenschutzbeauftragten, finden Sie auf folgenden Webseiten: <a href="/terms" title="Privacy policy">Privacy policy</a>
              </p>
            </details>

            <details>
              <summary>
                <h3>Automatisierte Entscheidungsfindung und Profilierung</h3> 
                <svg className={styles.summaryIcon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13L5 5M5 13l8-8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </summary>
              <p>
                Die automatische Verarbeitung personenbezogener Daten und das Profilieren können im Rahmen von Datenverarbeitungsaktivitäten stattfinden; dies ist der Fall, wenn Sie uns Ihre Zustimmung zur Verarbeitung von Marketing-Cookies geben, die auch dazu verwendet werden, spezielle Angebote auf der Grundlage von Informationen über Ihre Person anzuzeigen.
              </p>
            </details>

            <details>
              <summary>
                <h3>Zweck und Rechtsgrundlage der Verarbeitung</h3> 
                <svg className={styles.summaryIcon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13L5 5M5 13l8-8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </summary>
              <p>
                Die personenbezogenen Daten, die Sie uns beim Besuch unserer Webseite bereitstellen, verarbeiten wir zum Zweck der Personalisierung des Inhalts beim wiederholten Besuch unserer Webseite und der angezeigten Werbungen auf Webseiten Dritter.
                Die Rechtsgrundlage ist je nach Art des Cookies unterschiedlich, wie unten beschrieben.
                Um die Qualität unserer Dienste zu verbessern, verwenden wir sowohl dauerhafte als auch Session-Cookies, die nur funktionieren, während Sie sich auf der Website befinden.
                Im Rahmen der Cookies werden sowohl First-Party Cookies als auch Third-Party Cookies verwendet.
                First-party Cookies sind Cookie-Dateien, die direkt von uns eingestellt werden.
                Third-party Cookies sind Cookie-Dateien, die von einer Dritter Partei, nicht von uns, eingestellt werden. Solche Cookies stammen von verschiedenen Drittanbietern, die Cookies flexibel einsetzen, um das Nutzerverhalten auf den Webseiten außerhalb des Angebots des Drittanbieters zu verfolgen (insbesondere in Bezug auf gezielte Werbung). Die im Rahmen dieser Cookies erhobenen Daten werden von einem Dritten in eigener Verantwortung oder gemeinsam mit uns verarbeitet.
                Wir arbeiten bei der Verarbeitung der Cookies mit folgenden Drittanbietern als unabhängigen Datenverwaltern zusammen:
              </p>
              <ul>
                <li>Facebook: <a href="https://www.facebook.com/policies/cookies">https://www.facebook.com/policies/cookies</a></li>
                <li>Google Analytics: <a href="https://developers.google.com/analytics/devguides/collection/ga4/cookie-usage#gtagjs_google_analytics_4_-_cookie_usage">https://developers.google.com/analytics/devguides/collection/ga4/cookie-usage#gtagjs_google_analytics_4_-_cookie_usage</a></li>
                <li>Google login: <a href="https://policies.google.com/technologies/cookies#types-of-cookies">https://policies.google.com/technologies/cookies#types-of-cookies</a></li>
                <li>Hotjar: <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information#cookies-set-by-the-hotjar-script">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information#cookies-set-by-the-hotjar-script</a></li>
              </ul>
            </details>
        </section>
      </Container>
    </>
  )
}

export default CookiesPageDe